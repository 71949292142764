<template>
  <el-dialog
    :title="!disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      ref="dataForm"
      inline
      :model="dataForm"
      :rules="dataRule"
      label-width="80px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="会员昵称" prop="userId">
        <el-input
          v-model="dataForm.nickname"
          :disabled="true"
          placeholder="会员昵称"
        />
      </el-form-item>
      <el-form-item label="分销商名称" prop="name">
        <el-input
          v-model="dataForm.name"
          :disabled="true"
          placeholder="分销商名称"
        />
      </el-form-item>
      <el-form-item label="上级分销" prop="superiorId">
        <el-input
          v-model="dataForm.superiorNickname"
          :disabled="true"
          placeholder="上级分销"
        />
      </el-form-item>
      <el-form-item label="审核状态" prop="isAudit">
        <el-radio-group v-model="dataForm.isAudit" :disabled="disabled">
          <el-radio :label="false"> 未审核 </el-radio>
          <el-radio :label="true"> 已审核 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="加入时间" prop="joinTime">
        <el-input
          v-model="dataForm.joinTime"
          :disabled="true"
          placeholder="加入时间"
        />
      </el-form-item>
      <el-form-item label="分销等级" prop="degree">
        <el-input
          v-model="dataForm.degree"
          :disabled="true"
          placeholder="分销等级"
        />
      </el-form-item>
      <el-form-item label="邀请码" prop="invitationCode">
        <el-input
          v-model="dataForm.invitationCode"
          :disabled="true"
          placeholder="邀请码"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        userId: '',
        name: '',
        superiorId: '',
        nickname: '',
        superiorNickname: '',
        isAudit: '',
        joinTime: '',
        invitationCode: '',
        degree: '',
      },
    };
  },
  methods: {
    init(userId, disabled) {
      this.disabled = disabled;
      this.dataForm.userId = userId || '';
      console.log(userId);
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.userId) {
          this.$http({
            url: `/mall/dist/info/${this.dataForm.userId}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = data.dist;
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      console.log(this.dataForm.userId);
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/mall/dist/${!this.dataForm.userId ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
